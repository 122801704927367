<template>
    <div>
        <div class="header pb-8 pt-2 pt-lg-6 d-flex align-items-center">

        </div>
        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <div class="card shadow">
                        <div class="card-header border-0">
                            <div class="row align-items-center">
                                <div class="col"><h3 class="mb-0">Cenník skipass</h3></div>
                                <div class="col text-right">
                                    <a-button type="primary"  @click="addTerm = true">Pridať termín</a-button>
                                    <a-button type="primary"  @click="addLimit = true">Pridať obmedzenie</a-button>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive" :key="componentKey">
                          <div class="skipass-prices ant-table-body" :key="date + 'ski'" v-for="(skipassprice,date) in data">
                             <table style="width:100%" v-if="skipassprice[0]">
                                 <thead class="ant-table-thead" >
                                 <tr>
                                     <th>{{formatDateN(date)}}</th><th  :key="date + '_' + index"  v-for="index in 6">{{index}}D</th>
                                     <th style="width:240px;"> <span style="float:right">
                                    <a class="btn text-default btn-icon btn-secondary btn-sm" @click="editing(date)"><span class="btn-inner--icon" style="margin-right:5px"><i class="fas fa-pencil-alt"></i></span>Upraviť</a>
                                    <a-popconfirm placement="topRight"
                                                  @confirm="confirm(date)"
                                                  @cancel="cancel"
                                                  okText="Áno"
                                                  cancelText="Nie">
                                        <template slot="title">
                                            <h5 color="danger" >Prajete si tento obsah vymazať?</h5>
                                            Položka bude trvalo odstránená z vašej stránky.
                                        </template>
                                        <a-button type="secondary"  icon="fas fa-trash"    textColor="default" title="Zmazať">Zmazať</a-button>
                                    </a-popconfirm>
                                   </span></th>
                                 </tr>
                                 </thead>
                                 <tbody class="ant-table-tbody">
                                 <tr :key="'ro' + type.value + date" v-for="type in types"  v-if="skipassprice[type.value]">
                                     <th style="padding:10px">{{type.label}}</th>
                                     <td  :key="date + type.value + '_' + index"  v-for="index in 6">{{skipassprice[type.value][index]}} <span v-if="skipassprice[type.value][index] !== '-'">€</span></td>
                                     <td></td>
                                 </tr>
                                 </tbody>
                             </table>
                              <table style="width:100%" v-else>
                                  <thead class="ant-table-thead" >
                                  <tr>
                                      <th>{{formatDateN(date)}}</th><th>Uzatvorenie</th>
                                      <th style="width:240px;"> <span style="float:right">

                                    <a-popconfirm placement="topRight"
                                                  @confirm="confirm(date)"
                                                  @cancel="cancel"
                                                  okText="Áno"
                                                  cancelText="Nie">
                                        <template slot="title">
                                            <h5 color="danger" >Prajete si tento termín vymazať?</h5>
                                            Termín bude trvalo odstránený.
                                        </template>
                                        <a-button type="secondary"  icon="fas fa-trash"    textColor="default" title="Zmazať">Zmazať</a-button>
                                    </a-popconfirm>
                                   </span></th>
                                  </tr>
                                  </thead>
                              </table>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a-modal title="Pridať termín" centered v-model="addTerm" @ok="handleOk" okText="Uložiť" cancelText="Zrušiť">
            <div class="additional-setup">
                <a-range-picker class="mb-3"  v-model="skipass.daterange"  :locale="locale"  />
                <div :key="type.value + '-te'" v-for="type in types" class="prices-row">
                    <label>{{type.label}}</label>
                    <div class="price-input"  v-for="index in 6" :key="type.value + '_' + index" >
                        <strong v-if="parseInt(type.value) === 0">{{index}}D</strong>
                        <input :label="index + 'D'" type="number" v-model="skipass.prices[type.value][index]" style="width:100%;" class="priceInput"/>
                    </div>
                </div>
            </div>
        </a-modal>

        <a-modal title="Pridať obmedzenie" centered v-model="addLimit" @ok="handleLimit" okText="Uložiť" cancelText="Zrušiť">
            <div class="additional-setup">
                <a-range-picker class="mb-3"  v-model="skipass.daterange"  :locale="locale"  />
            </div>
        </a-modal>


    </div>
</template>
<script>
    import {dataService} from "../_services";
    import {Popconfirm} from "ant-design-vue"

    import Vue from "vue"
    import FormItem from 'ant-design-vue'

    import moment from 'moment'
    import 'moment/locale/sk';

    Vue.use(FormItem);

    var locale = {
        "lang": {
            "placeholder": "Vybrať dátum",
            "rangePlaceholder": ["Termín od", "do"],
            "today": "Dnes",
            "now": "Teraz",
            "backToToday": "Späť na dnes",
            "ok": "Ok",
            "clear": "Vymazať",
            "month": "Mesiac",
            "year": "Rok",
            "timeSelect": "Vybrať čas",
            "dateSelect": "Vybrať dátum",
            "monthSelect": "Vybrať mesiac",
            "yearSelect": "Vybrať rok",
            "decadeSelect": "Vybrať dekádu",
            "yearFormat": "YYYY",
            "dateFormat": "M/D/YYYY",
            "dayFormat": "D",
            "dateTimeFormat": "M/D/YYYY HH:mm:ss",
            "monthFormat": "MMMM",
            "monthBeforeYear": true,
            "previousMonth": "Predchádzajúci mesiac (PageUp)",
            "nextMonth": "Nasledujúci mesiac (PageDown)",
            "previousYear": "Minulý rok (Control + left)",
            "nextYear": "Nasledujúci rok (Control + right)",
            "previousDecade": "Minulá dekáda",
            "nextDecade": "Nasledujúca dekáda",
            "previousCentury": "Minulé storočie",
            "nextCentury": "Nasledujúce storoťie"
        },
        "timePickerLocale": {
            "placeholder": "Vybrať čas"
        },
        "dateFormat": "DD.MM.YYYY",
        "dateTimeFormat": "DD.MM.YYYY HH:mm:ss",
        "weekFormat": "YYYY-wo",
        "monthFormat": "YYYY-MM"
    }

    export default {
        name: "skipass",
        props: ["id"],
        components: {
            "a-popconfirm": Popconfirm
        },
        data() {
            return {
                data: [],
                ctt: [],
                colorsto: JSON.parse(localStorage.colors),
                loading: false,
                filteredInfo: null,
                componentKey:0,
                addLimit: false,
                rdateFormat: 'YYYY/MM/DD',
                skipass: {prices:{}},
                locale,
                types: [
                    {"value": '0', "label": 'Dospelý'},
                    {"value": '1', "label": 'Junior / senior'},
                    {"value": '2', "label": 'Dieťa'},
                ],
                addTerm: false,
                sortedInfo: null,
            };
        },
        computed: {
            columns() {
                let { sortedInfo, filteredInfo } = this;
                sortedInfo = sortedInfo || {};
                filteredInfo = filteredInfo || {};
                const columns = [
                    {
                        title: 'Popis bloku',
                        dataIndex: 'des',
                        sorter: true,
                        width: '20%',
                    },
                    {
                        title: 'Jazyk',
                        scopedSlots: {customRender: 'language'},
                        filters: this.language,
                        onFilter: (value, record) => record.language.includes(value),
                        dataIndex: 'language',
                    },
                    {
                        title: 'Typ obsahu',
                        scopedSlots: {customRender: 'contenttype'},
                        filters: this.ctt,
                        onFilter: (value, record) => record.content_type.includes(value),
                        dataIndex: 'showon',
                    },

                    {
                        align: 'right',
                        scopedSlots: {customRender: 'name'},
                    }
                ];
                return columns;     },
        },
        mounted() {
            this.fetch();
        },
        methods: {
            moment,
            formatDateN: function(dt){
                return moment(dt,'YYYY-MM-DD').format('D.M.YYYY')
            },
            confirm(e) {
                dataService.axiosDelete('skipass', e).then(results => {
                    if (results) {
                        delete this.data[e];
                        this.componentKey = this.componentKey + 1;
                    }
                });
            },
            handleLimit()
            {
                dataService.axiosPost('addskipasslimit', { 'skipass' : this.skipass}).then(results => {
                    this.fetch();
                    this.addLimit = false;
                });
            },
            handleOk(){
                dataService.axiosPost('addskipass', { 'skipass' : this.skipass}).then(results => {
                    this.fetch();
                    this.addTerm = false;
                });
            },
            cancel() {
            },
            handleChange(pagination, filters, sorter) {
                this.filteredInfo = filters;
                this.sortedInfo = sorter;
            },
            clearFilters() {
                this.filteredInfo = null;
            },
            editing(w)
            {

              this.skipass.prices = this.data[w];
                this.skipass.daterange = [moment(w,this.rdateFormat),moment(w,this.rdateFormat)];

               this.addTerm = true;
            },
            fetchcttitle(ct)
            {
                let tcc = this.contenttypes.find(x => x.slug === ct);
                return tcc.title;
            },
            fetch() {
                this.loading = true;
                /*
                                dataService.axiosFetch("updaterooms").then(results => {
                                console.log(results);
                                    this.loading = false;
                                });
                */dataService.axiosFetch("contenttypes").then(results => {
                    this.contenttypes = results;

                    results.forEach(obj => {
                        var iid = obj.id;
                        var vval = obj.title;
                        this.ctt.push({"text" : vval,"value" : iid});


                    });
                });
                dataService.axiosFetch('skipassprices').then(results => {

                    this.types.forEach(obj => {
                        this.skipass.prices[obj.value] = {};

                    });

                    this.data = results;
                    this.loading = false;
                });
            }

        },
    };
</script>

<style>
    .prices-row label
    {width:100px;}
.prices-row
{display:inline-block;}
.priceInput
{border:1px solid #bdd1e4;}
.price-input
{text-align:center;
}
.skipass-prices td{
    min-width:150px;
}
.skipass-prices tbody th{
    min-width:250px;
    font-weight:100;
}
.price-input strong
{margin-bottom:5px;display:block;}
    .price-input
    {
       margin-left:10px; width:50px;display:inline-block;
    }
    ul.ant-pagination {
        margin-top: 0;
        margin-bottom: 1em;
    }


    .ant-table-pagination.ant-pagination {
        margin-bottom: 40px;
        margin-right: 20px;
    }

    .ant-pagination-item {
        border: 0;
    }

    .ant-table {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        margin-bottom: 20px;
    }

    .ant-pagination-item a, .ant-pagination-prev a, .ant-pagination-next a, .ant-pagination-jump-prev a, .ant-pagination-jump-next a {
        -webkit-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        border: 0;
    }

    .ant-pagination-item a, .ant-pagination-prev a, .ant-pagination-next a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 0;
        margin: 0 3px;
        border-radius: 50% !important;
        width: 36px;
        height: 36px;
        font-size: 0.875rem;
    }

    .ant-pagination-item.ant-pagination-item-active a {
        z-index: 1;
        color: #fff;
        background-color: #5e72e4;
        border-color: #5e72e4;
    }

    .ant-pagination-item-link.ant-pagination-disabled a {
        color: #8898aa;
        pointer-events: none;
        cursor: auto;
        background-color: #fff;
        border-color: #dee2e6;
    }

</style>
